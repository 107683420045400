import React from "react";
import clsx from "clsx";

import styles from "./Container.styles";

const Container = props => {
	const css = styles();
	const { children, className } = props;

	return (
		<div className={clsx(css.container, className, "mco-view-component-container")}>
			{children}
		</div>
	);
};

export default Container;
