import React from "react";
import clsx from "clsx";
import useTheme from "@material-ui/core/styles/useTheme";
import ReactLink from "@micado-digital/react-link/ReactLink";

import styles from "./MetaMenuItem.styles";

const MetaMenuItem = ({ item, selected }) => {
	const theme = useTheme();
	const css = styles(theme);

	return (
		<li className={clsx(css.item, "mco-view-component-metamenu__item")}>
			<ReactLink
				className={clsx(css.link, "mco-view-component-metamenu__link", {
					[css.selected]: selected,
					"mco-view-component-metamenu__item--selected": selected
				})}
				to={item.link}
			>{item.title}
			</ReactLink>
		</li>
	);
};

export default MetaMenuItem;
