const animationContainer2 = {
	animate: {
		transition: {
			delayChildren: 0.5,
			staggerChildren: 0.08
		}
	}
};

const animationItem2 = {
	initial: {
		opacity: 0,
		x: -28
	},
	animate: {
		opacity: 1,
		transition: {
			stiffness: 80,
			type: "spring"
		},
		x: 0
	}
};

export { animationContainer2, animationItem2 };
