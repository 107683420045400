const animationContainer1 = {
	animate: {
		transition: {
			staggerChildren: 0.1
		}
	}
};

const animationItem1 = {
	initial: {
		opacity: 0,
		y: -28
	},
	animate: {
		opacity: 1,
		transition: {
			stiffness: 80,
			type: "spring"
		},
		y: 0
	}
};

export { animationContainer1, animationItem1 };
