import React from "react";
import clsx from "clsx";
import MetaMenuItem from "../MetaMenuItem";

import styles from "./MetaMenuList.styles";

const MetaMenuList = ({ items, selectedMenuItemID }) => {
	const css = styles();

	return (
		<ul className={clsx(css.MenuList, "mco-view-component-metamenu__menulist")}>
			{items?.map(item => {
				const {id} = item

				return (
					<MetaMenuItem
						item={item}
						key={id}
						selected={id === selectedMenuItemID}
					/>
				)
			})}
		</ul>
	);
};

export default MetaMenuList;
