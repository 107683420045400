import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	container: {
		margin: "0 auto",
		maxWidth: theme.breakpoints.values.xl,
		padding: theme.spacing(0, 2)
	}
}));

export default styles;
